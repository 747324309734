import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import styles from "../components/ARWebPayment.module.css";

import "./../assets/css/custom.css";

import {
  THEME_COLOR2,
  THEME_COLOR as THEMECOLOR,
} from "../containers/MenuList2";
import { THEME_COLOR } from "../constants";
import i18next from "../constants/i18n";

import { ReactComponent as EditIcon } from "../assets/images/edit.svg";
import CustomTipModal from "../modules/custom-tip-modal/CustomTipModal";

const tipsOptions = [0, 5, 10, "custom"];

let bgColor = {
  temp1: THEME_COLOR,
  temp2: THEME_COLOR2,
};

let textColor = {
  temp1: "#000",
  temp2: "#FFF",
};

let editColor = {
  temp1: "#000",
  temp2: "#FFF",
};

let fontStyle1 = {
  temp1: {},
  temp2: {
    fontFamily: "GilroySemiBold !important",
  },
};

const ARWebPayment = (props) => {
  let { restTemplate, totalCartPrice, themeColors = {} } = props;

  const {
    checkoutFontColor = null,
    checkoutTipsBgColor = null,
    checkoutTipsSelectedBgColor = null,
    checkoutTipsFontColor = null,
    checkoutTipsSelectedFontColor = null,
    checkoutTipsPencilIconColor = null,
  } = themeColors || {};

  const [tipValue, setTipValue] = useState(0);
  const [customTipModalOpen, setCustomTipModalOpen] = useState(false);

  useEffect(() => {
    props.onSetTipValue(0);
  }, [totalCartPrice]);

  const onTipPercentageSelect = (tip) => {
    let val = tip;
    if (tip === tipValue) {
      val = 0;
    }
    setTipValue(val);
    if (tip !== "custom") {
      const tipAmount = (totalCartPrice * tip) / 100;
      props.onSetTipValue(tipAmount, false);
    } else {
      setTipValue(null);
      setCustomTipModalOpen(true);
      props.onSetTipValue(0, true);
    }
  };

  const handleCustomTipModalClose = (customTipAmount) => {
    setCustomTipModalOpen(false);
    setTipValue(customTipAmount);
    props.onSetTipValue(customTipAmount, true);
  };
  return (
    <>
      <Grid
        container
        item
        mt={2}
        xs={12}
        justifyContent={"center"}
        px={2}
        className={styles.mainView}
      >
        <Grid item xs={12}>
          <Box mb={2}>
            <Box>
              <Typography
                className={styles.subTitleText}
                sx={{
                  ...fontStyle1[restTemplate],
                  color : `${checkoutFontColor ?? "#212529"} !important`,
                  fontFamily: "Inter !important",
                  fontWeight: "600 !important",
                  fontSize: "20px !important",
                }}
              >
                {i18next.t("Tips")}
              </Typography>
              <Typography
                className={styles.tipDescText}
                sx={{
                  color : `${checkoutFontColor ?? "#373737"} !important`,
                  fontFamily: "Inter !important",
                  fontWeight: "400 !important",
                  fontSize: "14px !important",
                }}
              >
                {i18next.t(
                  "Thank you for your generous tips for future orders."
                )}
                &lrm;
              </Typography>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                {tipsOptions.map((tipOption, index) => {
                  return (
                    <Box
                      key={index}
                      onClick={() => onTipPercentageSelect(tipOption)}
                      className={`${styles.tipListView} checkout-tip-view`}
                      sx={{
                        cursor: { xl: "pointer" },
                        path: {
                          fill:
                            tipValue === tipOption ||
                            (tipValue === null && tipOption === "custom")
                              ? checkoutTipsSelectedFontColor ?? editColor[restTemplate]
                              : restTemplate === "temp1"
                              ? "#000"
                              : checkoutTipsPencilIconColor ?? THEMECOLOR,
                        },
                      }}
                      style={{
                        backgroundColor:
                          tipValue === tipOption ||
                          (tipValue === null && tipOption === "custom")
                            ? checkoutTipsSelectedBgColor ?? "rgba(29, 49, 83, 0.5)"
                            : checkoutTipsBgColor ?? "#FFFFFF",
                      }}
                    >
                      <Typography
                        className={styles.tipListText}
                        sx={{
                          color:
                            tipValue === tipOption ||
                            (tipValue === null && tipOption === "custom")
                              ? checkoutTipsSelectedFontColor ?? textColor[restTemplate]
                              : checkoutTipsFontColor ?? "#000",
                          fontSize: "20px !important",
                          fontWeight: "100 !important",
                          fontFamily: "Inter !important",
                        }}
                      >
                        {tipOption === "custom" ? (
                          <EditIcon width={35} height={35} />
                        ) : (
                          `${tipOption}%`
                        )}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <CustomTipModal
        open={customTipModalOpen}
        themeColors={themeColors}
        onClose={handleCustomTipModalClose}
      />
    </>
  );
};

export default ARWebPayment;
