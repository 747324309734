import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import {
  THEME_COLOR as THEMECOLOR,
  THEME_COLOR2,
} from "../containers/MenuList2";
import { THEME_COLOR } from "../constants";

import { ReactComponent as EditIcon } from "../assets/images/edit_yellow.svg";
import { ReactComponent as RadioOnIcon } from "../assets/images/radio_on2.svg";
import {useSelector} from "react-redux";

let btnViewStyle = {
  temp1: {},
  temp2: {
    color: "#FFF",
    fontFamily: "GilroySemiBold",
    backgroundColor: "#cb6464",
    borderRadius: "0px",
    width: "49.5%",
    "&:hover": {
      backgroundColor: `#cb6464 !important`,
    },
  },
};

let cancelBtnViewStyle = {
  temp1: {},
  temp2: {
    fontFamily: "GilroySemiBold",
    backgroundColor: THEME_COLOR2,
    borderRadius: "0px",
    width: "49.5%",
    "&:hover": {
      backgroundColor: `${THEME_COLOR2} !important`,
    },
  },
};

let checkBoxStyle = {
  temp1: {},
  temp2: {
    width: "17px",
    height: "17px",
    borderRadius: "12px",
    ml: "1.8px",
  },
};

let fontFamilyStyle = {
  temp1: {},
  temp2: {
    fontFamily: "GilroyMedium",
  },
};

const ARWebAddressList = (props) => {
  let { restTemplate } = props;
  const [selectedAddress, setSelectedAddress] = useState("");

  const themeColors = useSelector((state) => state.restaurantData?.restaurantDetail?.themeColors);

  const {
    addressListAddBtnFontColor = null,
    addressListCancelBtnBgColor = null,
    addressListCancelBtnFontColor = null,
    addressListCardEditIconColor = null,
    addressListSaveBtnBgColor = null,
    addressListSaveBtnFontColor = null,
  } = themeColors || {};

  const styles = styles1(themeColors);


  useEffect(() => {
    setSelectedAddress(props.address);
  }, [props.address]);

  const onSubmitAddress = () => {
    props.onSubmitAddress(selectedAddress);
  };

  const onSelectAddress = (val) => {
    setSelectedAddress(val);
  };

  return (
    <Box
      pt={restTemplate == "temp1" ? 2 : 0}
      px={restTemplate == "temp1" ? 2 : 0}
      pb={restTemplate == "temp1" ? 1 : 0}
      sx={styles.dataView}
    >
      <Box
        p={restTemplate == "temp1" ? 0 : 2}
        sx={{
          ...styles.mainView,
          height: window.innerHeight - (restTemplate == "temp1" ? 195 : 186),
        }}
      >
        <Typography
          sx={{ ...styles.titleText, ...fontFamilyStyle[restTemplate] }}
        >
          Choose an Address
        </Typography>
        {props.addressList.map((x, i) => {
          return (
            <Box sx={styles.addressMainView} onClick={() => onSelectAddress(x)}>
              <Box sx={styles.checkBoxMainView}>
                {selectedAddress?.id == x.id ? (
                  restTemplate == "temp1" ? (
                    <Box sx={styles.selectedCheckBoxView} />
                  ) : (
                    <RadioOnIcon width={25} height={25} />
                  )
                ) : (
                  <Box
                    sx={{
                      ...styles.checkBoxView,
                      ...checkBoxStyle[restTemplate],
                    }}
                  />
                )}
              </Box>
              <Box sx={styles.addressView}>
                <Box sx={styles.addressEditView}>
                  <Typography
                    sx={{
                      ...styles.addressSlNoText,
                      ...fontFamilyStyle[restTemplate],
                    }}
                    mb={0.3}
                  >
                    Address {i + 1}
                  </Typography>
                  <Box
                    sx={{
                      cursor: "pointer",
                      path: {fill: addressListCardEditIconColor ?? "#E8C900"},
                      rect: {fill: addressListCardEditIconColor ?? "#E8C900"},
                      ".st0": { fill : "none"}
                    }}
                    onClick={() => props.editAddress(x)}
                  >
                    <EditIcon
                      width={20}
                      height={20}
                      style={{
                        transform:
                          props.locale == "ar" ? "scaleX(-1)" : "unset",
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  sx={{
                    ...styles.addressText,
                    ...fontFamilyStyle[restTemplate],
                  }}
                >
                  {x.name}
                </Typography>
                <Typography
                  sx={{
                    ...styles.addressText,
                    ...fontFamilyStyle[restTemplate],
                  }}
                  mt={restTemplate == "temp1" ? 0 : "4px"}
                >
                  {x.addressTitle}
                </Typography>
                <Typography
                  sx={{
                    ...styles.addressText,
                    ...fontFamilyStyle[restTemplate],
                  }}
                  mt={restTemplate == "temp1" ? 0 : "4px"}
                >
                  {x.address}
                </Typography>
              </Box>
            </Box>
          );
        })}

        <Box my={3} sx={styles.addAddressView}>
          <Box sx={styles.checkBoxMainView} />
          <Box sx={styles.addressAddMainView} onClick={props.addNewAddress}>
            <Box sx={styles.addressAddView}>
              <Typography
                sx={{
                  ...styles.addressAddText,
                  color: addressListAddBtnFontColor ?? (restTemplate == "temp1" ? "#C1A700" : THEMECOLOR),
                }}
              >
                +
              </Typography>
            </Box>
            <Typography
              ml={props.locale == "ar" ? "unset" : 1}
              mr={props.locale == "ar" ? 1 : "unset"}
              sx={{
                ...styles.addAddressText,
                color: addressListAddBtnFontColor ?? (restTemplate == "temp1" ? "#C1A700" : THEMECOLOR),
              }}
            >
              Add new address
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box mt={3} mb={restTemplate == "temp1" ? 2 : 0} sx={styles.btnView}>
        <Button
          type="button"
          sx={{ ...styles.cancelBtn, ...cancelBtnViewStyle[restTemplate],
            backgroundColor : addressListCancelBtnBgColor ?? THEME_COLOR2,
            color : addressListCancelBtnFontColor ?? "#FFF",
            "&:hover": {
              backgroundColor: `${addressListCancelBtnBgColor ?? THEME_COLOR2}!important`,
            },
          }}
          onClick={props.onCancelAddress}
        >
          Cancel
        </Button>
        <Button
          type="button"
          sx={{ ...styles.submitBtn, ...btnViewStyle[restTemplate],
            backgroundColor : addressListSaveBtnBgColor ?? "#cb6464",
            color : addressListSaveBtnFontColor ?? "#FFF",
            "&:hover": {
              backgroundColor: `${addressListSaveBtnBgColor ?? "#cb6464"}!important`,
            },
          }}
          onClick={() => onSubmitAddress()}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default ARWebAddressList;

const styles1 = (theme) => ({
  dataView: {
    backgroundColor: theme?.addressListBgColor ?? "#FFF",
  },
  mainView: {
    overflow: "auto",
  },
  titleText: {
    color: theme?.addressListFontColor ?? "#212529",
    fontSize: 18,
    fontFamily: "GilroyBold",
  },
  addressMainView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    mt: 2,
  },
  addressView: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    backgroundColor: theme?.addressListCardBgColor ?? "#F5F5F5",
    p: 2,
  },
  addressEditView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addressSlNoText: {
    color: theme?.addressListCardPrimaryFontColor ?? "#868E96",
    fontSize: 11,
    fontFamily: "GilroyBold",
  },
  addressText: {
    color: theme?.addressListCardSecondaryFontColor ??  "#212529",
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    lineHeight: "15px",
  },
  checkBoxMainView: {
    width: 45,
    path: {
      fill : theme?.addressListRadioBtnIconColor ?? "#000",
    }
  },
  selectedCheckBoxView: {
    border: "7px solid #E8C900",
    width: "12px",
    height: "12px",
    borderRadius: "20px",
  },
  checkBoxView: {
    border: `2px solid ${theme?.addressListRadioBtnIconColor ?? "#BFBFBF"}`,
    width: "20px",
    height: "20px",
    borderRadius: "15px",
  },
  addAddressView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  addressAddMainView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  addressAddView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme?.addressListAddBtnBgColor ?? "#FAF4CB",
    width: "16px",
    height: "16px",
    borderRadius: "5px",
  },
  addressAddText: {
    color: "#C1A700",
    fontSize: 20,
    fontFamily: "GilroyMedium",
  },
  addAddressText: {
    fontSize: 14,
    fontFamily: "GilroyMedium",
  },

  btnView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cancelBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000000",
    cursor: "pointer",
    textTransform: "capitalize",
    color: "#FFF",
    fontFamily: "GilroyBold",
    fontSize: 18,
    borderRadius: "40px",
    height: 60,
    width: "48%",
    "&:hover": {
      backgroundColor: "#000000 !important",
    },
  },
  submitBtn: {
    backgroundColor: THEME_COLOR,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    color: "#000",
    fontFamily: "GilroyBold",
    fontSize: 18,
    borderRadius: "40px",
    width: "48%",
    height: 60,
    "&:hover": {
      backgroundColor: THEME_COLOR + " !important",
    },
  },
});
