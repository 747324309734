import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  FormControl,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomTipModal({ open, themeColors, onClose }) {
  const [value, setValue] = React.useState('0');

  const {
    checkoutTipsPopupBgColor = null,
    checkoutTipsPopupFontColor = null,
    checkoutTipsPopupLabelColor = null,
    checkoutTipsPopupTitleColor = null,
    checkoutTipsPopupAddBtnBgColor = null,
    checkoutTipsPopupAddBtnFontColor = null,
  } = themeColors || {};

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    let tipAmount =
      formJson.tipAmount.trim() === '' ? 0 : parseInt(formJson.tipAmount, 10);

    if (isNaN(tipAmount) || tipAmount <= 0) {
      tipAmount = 0;
    }

    onClose(tipAmount);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => onClose(0)}
        fullWidth
        maxWidth='xs'
        PaperProps={{
          component: 'form',
          onAbort: (event) => {
            event.preventDefault();
            onClose(0);
          },
          onSubmit: handleSubmit,
          sx: {
            backgroundColor : checkoutTipsPopupBgColor ?? "#FFF",
            borderRadius: '10px !important',
            width: '280px',
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: 'Inter !important',
            fontSize: '18px !important',
            fontWeight: '600 !important',
            color: `${checkoutTipsPopupTitleColor ?? "#000"} !important`,
          }}
        >
          Tip Amount
          <IconButton
            aria-label='close'
            onClick={() => onClose(0)}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '8px',
              background: 'none !important',
            }}
          >
            <CloseIcon
              sx={{
                color: 'rgba(0, 0, 0, 0.4)',
                fontSize: '24px',
                '&:hover': {
                  color: 'rgba(0, 0, 0, 0.87)',
                },
              }}
            />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            px: 3,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FormControl
            sx={{ mt: 1, width: '150px', height: '100%', display: 'flex' }}
          >
            <InputLabel
              htmlFor='outlined-adornment-amount'
              sx={{color: checkoutTipsPopupLabelColor ?? "#000"}}
            >
              Amount
            </InputLabel>
            <OutlinedInput
              name='tipAmount'
              id='tipAmount'
              startAdornment={
                <InputAdornment position='start'>&pound;</InputAdornment>
              }
              endAdornment={
                <InputAdornment position='end'>
                  <Typography
                    variant='h6'
                    sx={{
                      fontFamily: 'Inter',
                      fontWeight: 600,
                      fontSize: '16px',
                      background: 'rgba(189, 153, 104, 0.08)',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 10px',
                    }}
                  >
                    .00
                  </Typography>
                </InputAdornment>
              }
              label='Amount'
              autoFocus
              required
              margin='dense'
			  onChange={(e) => {
				const value = e.target.value.replace(/\D/g, '');
				if (value === '' || parseInt(value, 10) <= 100) {
				  setValue(value);
				}
			  }}
              inputProps={{
                min: 1,
				max: 100,
                pattern: '\\d*',
                sx: { fontFamily: 'Inter', fontWeight: 600, fontSize: '16px', color: checkoutTipsPopupFontColor ?? "#000" },
              }}
              value={value}
            />
          </FormControl>
          <Button
            onClick={onClose}
            type='submit'
            sx={{
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '14px',
              background: `${checkoutTipsPopupAddBtnBgColor ?? "#BD9968"} !important`,
              color: `${checkoutTipsPopupAddBtnFontColor ?? "#ffffff"} !important`,
              height: '56px',
              width: '89px',
              mt: 1,
              ml: 1,
            }}
          >
            Add Tip
          </Button>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            pb: 3,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='body2'
            sx={{
              color : checkoutTipsPopupFontColor ?? "#000",
              fontSize: '12px',
              fontFamily: 'Inter',
              fontWeight: 300,
              textAlign: 'center',
            }}
          >
            Grateful for your gratuity—thank you!
          </Typography>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
