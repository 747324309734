import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutHeader from "../eazy-peasy-checkout/components/shared/CheckoutHeader";
import OrderStatus from "./components/OrderStatus";
import { formatDate } from "../../utils/utils";
import YourBillWidget from "./components/YourBillWidget";
import { formatCurrency } from "../../utils/utils";
import CartItemsWithModifiers from "./components/CartItemsWithModifiers";
import {useSelector} from "react-redux";

const PastOrderDetailsPage = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const orderId = location.state?.order?._id;

  const themeColors = useSelector((state) => state.restaurantData?.restaurantDetail?.themeColors);

  const {
    orderDetailBgColor = null,
    orderDetailCardBgColor = null,
    orderDetailCardFontColor = null,
    orderDetailHeaderBgColor = null,
    orderDetailHeaderFontColor = null,
    orderDetailCardBillWidgetBgColor = null,
    orderDetailCardBillWidgetFontColor = null
  } = themeColors || {};

	const pastOrders = JSON.parse(localStorage.getItem("pastOrders"));

	const order = pastOrders.find((item) => item._id === orderId);

	const handleBackButtonClick = () => {
		navigate("/restaurant/past-orders");
	};

	if (!order) {
		return <Typography>Order not found</Typography>;
	}

	return (
		<Box className={'device-width-view'}>
      <Box className={'device-width-pct'}>
		  	 <CheckoutHeader
			  	 headerTitle='Your Orders'
				   onBackButtonClick={handleBackButtonClick}
           bgColor={orderDetailHeaderBgColor ?? "#FFF"}
           fontColor={orderDetailHeaderFontColor ?? "#000"}
			   />
      </Box>

			<Box
        className={'device-width-pct'}
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
					height: "100vh",
					boxSizing: "border-box",
					maxHeight: "100vh",
					overflowY: "auto",
					backgroundColor: orderDetailBgColor ?? "#FAFBFF",
				}}
			>
				<Box
					sx={{
						p: 2,
						mx: 1,
						mt: 2,
						backgroundColor: orderDetailCardBgColor ?? "white",
						boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.1)",
						borderRadius: "15px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							alignItems: "center",
						}}
					>
						<Typography
							variant='h6'
							sx={{
							  color: orderDetailCardFontColor ?? "#000",
								fontWeight: 600,
								fontFamily: "Inter",
								fontSize: 14,
								lineHeight: 1,
							}}
						>
							Order: #{order?._id?.toUpperCase().substr(-5)}
						</Typography>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								flexDirection: "column",
								gap: 1,
							}}
						>
							<OrderStatus status={order?.transactionStatus} />
						</Box>
					</Box>

					{(order &&
						order?.cart?.length) ? (
              <CartItemsWithModifiers cartList={order?.cart} fontColor={orderDetailCardFontColor} />
            ) : null}

					<Typography
						sx={{
              color: orderDetailCardFontColor ?? "#000",
							fontFamily: "Inter",
							fontWeight: 500,
							fontSize: 12,
							lineHeight: 1,
							py: 1,
							textAlign: "center",
						}}
					>
						Placed at {formatDate(order?.createdAt)} on Table {order?.tableNumber}
					</Typography>
				</Box>

				<Box sx={{}}>
					<YourBillWidget
						totalCartPrice={formatCurrency(order?.totalAmount)}
						subTotal={
							order?.totalAmount -
								(order?.tip ?? 0) -
								(order?.serviceCharge ?? 0) || 0
						}
						discount={order?.totalDiscount || 0}
						taxValue={order?.tax || 0}
						tipValue={order?.tip || 0}
						serviceCharge={order?.serviceCharge || 0}
						restTemplate='temp1'
						bgColor={orderDetailCardBillWidgetBgColor}
						fontColor={orderDetailCardBillWidgetFontColor}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default PastOrderDetailsPage;
