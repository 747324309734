import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "./MenuListAddSubMenuBtn.module.css";

const MenuListAddSubMenuBtn = (props) => {
  const { theme = "theme1", bgColor = null, borderColor = null, fontColor = null } = props;

  const handlerOnAddMenu = (e) => {
    e.stopPropagation();
    props.onAddSubMenu("+");
  };

  const handlerOnSubMenu = (e) => {
    e.stopPropagation();
    props.onAddSubMenu("-");
  };

  let btnStyle = {
    backgroundColor: bgColor ?? "#ffffff",
    border: `1px solid ${borderColor ?? "#000000"}`,
  };

  let textStyle = {
    color: fontColor ?? "#000000",
  };

  if (theme === "theme1") {
    btnStyle = {
      backgroundColor: bgColor ?? "#b99c74",
      border: `1px solid ${borderColor ?? "#b99c74"}`,
    };
    textStyle = {
      color: fontColor ?? "#ffffff",
    };
  }

  return (
    <Box
      className={`${styles?.["main-view"]} ${
        styles?.[theme === "theme1" ? "theme1-view" : "theme2-view"]
      }`}
      sx={{
        ...btnStyle,
        width: props?.inputWidth ? props.inputWidth : 100,
        height: props?.inputHeight ? props.inputHeight : 35,
      }}
    >
      <Box
        className={styles.addSubView}
        onClick={handlerOnSubMenu}
        sx={{ width: props?.addSubWidth ? props.addSubWidth : 40 }}
      >
        <Typography
          sx={textStyle}
          className={`${styles?.["add-sub-text"]}`}
        >
          -
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={textStyle}
          className={`${styles?.["count-text"]}`}
        >
          {props.count}
        </Typography>
      </Box>
      <Box
        className={styles.addSubView}
        onClick={handlerOnAddMenu}
        sx={{ width: props?.addSubWidth ? props.addSubWidth : 40 }}
      >
        <Typography
          sx={textStyle}
          className={`${styles?.["add-sub-text"]}`}
        >
          +
        </Typography>
      </Box>
    </Box>
  );
};

export default MenuListAddSubMenuBtn;
