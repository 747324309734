import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import styles from "./MenuTypeFilter.module.css";

import { ReactComponent as MenuVegIcon } from "../assets/images/veg_icon.svg";
import { ReactComponent as MenuNonVegIcon } from "../assets/images/non_veg_icon.svg";
import { MENU_TYPE2 } from "../constants";

const MenuTypeFilter = (props) => {
  const { bgColor = "#FFF", fontColor = "#000" } = props;

  const theme = createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root": {
              backgroundColor: bgColor ?? "#FFF",
            },
            "& .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper":
              props?.left
                ? {
                    left: `${props?.left}`,
                  }
                : null,
          },
        },
      },
    },
  });

  let list = [
    {
      id: "all",
      name: "All",
    },
  ];

  props.list?.map((x) => {
    if (x == "Non vegetarian") {
      list.push({
        id: x,
        name: "Non veg",
      });
    } else if (x == "Vegetarian") {
      list.push({
        id: x,
        name: "Veg",
      });
    } else {
      list.push({
        id: x,
        name: x,
      });
    }
  });

  return (
    <FormControl variant="filled">
      <ThemeProvider theme={theme}>
        <Select
          value={props.value}
          onChange={(event) => props.setValue(event.target.value)}
          className={styles.selectView}
          sx={{
            height: props?.height ? props?.height : 40,
            "&.MuiInputBase-root": {
              color: `${fontColor ?? "#000"} !important`,
              backgroundColor: `${bgColor ?? "#FFF"} !important`,
              "&:hover": {
                backgroundColor: bgColor ?? "#FFF",
              },
              "&.Mui-focused": {
                backgroundColor: bgColor ?? "#FFF",
              },
              "&:before, :after, :hover:not(.Mui-disabled):before": {
                borderBottom: "0px !important",
              },
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                backgroundColor: bgColor ?? "#FFF",
                py: 0,
              },
              "& .MuiSvgIcon-root": {
                color: fontColor ?? "#000"
              },
            },
          }}
          py={1.3}
          px={0.5}
        >
          {list?.map((item, index) => (
            <MenuItem
              key={index}
              value={item?.id}
              className={styles.selectValue}
              sx={{color: fontColor ?? "#000"}}
            >
              {item?.id == "all" ? (
                <>
                  <span style={{ marginRight: 5, lineHeight: 0 }}>
                    {MENU_TYPE2?.["Vegetarian"]}
                  </span>
                  <span style={{ marginRight: 5, lineHeight: 0 }}>
                    {MENU_TYPE2?.["Non vegetarian"]}
                  </span>
                </>
              ) : MENU_TYPE2?.[item?.id] ? (
                <span style={{ marginRight: 5, lineHeight: 0 }}>
                  {MENU_TYPE2?.[item?.id]}
                </span>
              ) : null}
              {item?.name}
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>
    </FormControl>
  );
};

export default MenuTypeFilter;
