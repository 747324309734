import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import ARWebCartTextInput from "./ARWebCartTextInput";
import ARWebAddressForm from "./ARWebAddressForm";
import AlertMsg from "./AlertMsg";

import "./../assets/css/custom.css";

import { THEME_COLOR } from "../constants";
import i18next from "../constants/i18n";
import {
  THEME_COLOR as THEMECOLOR,
  THEME_COLOR2,
} from "../containers/MenuList2";

import { ReactComponent as RadioOnIcon } from "../assets/images/radio_on2.svg";

let billing_type = [
  {
    label: i18next.t("Personal"),
    value: "personal",
  },
  {
    label: i18next.t("Commercial"),
    value: "commercial",
  },
];

let btnViewStyle = {
  temp1: {},
  temp2: {
    color: "#FFF",
    fontFamily: "GilroySemiBold",
    backgroundColor: "#cb6464",
    borderRadius: "0px",
    width: "49.5%",
    "&:hover": {
      backgroundColor: `#cb6464 !important`,
    },
  },
};

let cancelBtnViewStyle = {
  temp1: {},
  temp2: {
    fontFamily: "GilroySemiBold",
    backgroundColor: THEME_COLOR2,
    borderRadius: "0px",
    width: "49.5%",
    "&:hover": {
      backgroundColor: `${THEME_COLOR2} !important`,
    },
  },
};

let checkBoxStyle = {
  temp1: {},
  temp2: {
    width: "17px",
    height: "17px",
    borderRadius: "12px",
    ml: "1.8px",
  },
};

let subViewStyle = {
  temp1: {},
  temp2: {
    mb: 1.5,
    mx: 1,
    borderRadius: 3,
  },
};

const ARWebAddAddress = (props) => {
  let { restTemplate } = props;
  const [recipientName, setRecipientName] = useState("");
  const [phCode, setPhCode] = useState("49");
  const [phNo, setPhNo] = useState("");
  const [email, setEmail] = useState("");
  const [addressTitle, setAddressTitle] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [billingSameAsDelivery, setBillingSameAsDelivery] = useState(true);
  const [billingAddressTitle, setBillingAddressTitle] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingPincode, setBillingPincode] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [billingType, setBillingType] = useState("personal");
  const [pincodeList, setPincodeList] = useState([
    "123456",
    "6789012",
    "3456789",
  ]);
  const [cityList, setCityList] = useState(["city1", "city2", "city3"]);
  const [countryList, setCountryList] = useState([
    "country1",
    "country2",
    "country3",
  ]);
  const [initial, setInitial] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const themeColors = useSelector((state) => state.restaurantData?.restaurantDetail?.themeColors);

  const {
    addAddressCancelBtnBgColor = null,
    addAddressCancelBtnFontColor = null,
    addAddressCardInputBgColor = null,
    addAddressCardInputFontColor = null,
    addAddressCardInputLabelFontColor = null,
    addAddressCheckboxBtnIconColor = null,
    addAddressSaveBtnBgColor = null,
    addAddressSaveBtnFontColor = null,
  } = themeColors || {};

  const styles = styles1(themeColors);

  const InputViewStyle = {
    backgroundColor: addAddressCardInputBgColor ?? "#FCFCFD",
    border: "1px solid #CFD8E8",
    borderRadius: "8px",
  };

  useEffect(() => {
    if (props.editAddress == null) {
      setInitial(false);
    }
  }, [props.page, props.editAddress]);

  useEffect(() => {
    if (props.page == "edit_address" || props.page == "edit_billing_address") {
      let data = props.editAddress;
      setRecipientName(data.name);
      setEmail(data.email);
      setPhNo(data.phone);
      setPhCode(data.phoneCode);
      setBillingType(data.billingType);
      setBillingSameAsDelivery(data.billingSameAsDelivery);
      setAddressTitle(data.addressTitle);
      setAddress(data.address);
      setPincode(data.pincode);
      setCity(data.city);
      setCountry(data.country);
      if (data.billingSameAsDelivery) {
        setBillingAddressTitle(data.addressTitle);
        setBillingAddress(data.address);
        setBillingPincode(data.pincode);
        setBillingCity(data.city);
        setBillingCountry(data.country);
      } else {
        if (props.page == "edit_billing_address") {
          setBillingAddressTitle("");
          setBillingAddress("");
          setBillingPincode("");
          setBillingCity("");
          setBillingCountry("");
        } else {
          setBillingAddressTitle(data.billingAddressTitle);
          setBillingAddress(data.billingAddress);
          setBillingPincode(data.billingPincode);
          setBillingCity(data.billingCity);
          setBillingCountry(data.billingCountry);
        }
      }

      setPincodeList([
        data.pincode,
        ...pincodeList.filter((x) => x != data.pincode),
      ]);
      setCityList([data.city, ...cityList.filter((x) => x != data.city)]);
      setCountryList([
        data.country,
        ...countryList.filter((x) => x != data.country),
      ]);

      if (!data.billingSameAsDelivery) {
        if (data.pincode != data.billingPincode) {
          let pincode_list = pincodeList.filter(
            (x) => x != data.pincode && x != data.billingPincode
          );
          if (data.billingPincode != "") {
            pincode_list = [data.billingPincode, ...pincode_list];
          }
          if (data.pincode != "") {
            pincode_list = [data.pincode, ...pincode_list];
          }
          setPincodeList(pincode_list);
        }
        if (data.city != data.billingCity) {
          let city_list = cityList.filter(
            (x) => x != data.city && x != data.billingCity
          );
          if (data.billingCity != "") {
            city_list = [data.billingCity, ...city_list];
          }
          if (data.city != "") {
            city_list = [data.city, ...city_list];
          }
          setCityList(city_list);
        }

        if (data.country != data.billingCountry) {
          let country_list = countryList.filter(
            (x) => x != data.country && x != data.billingCountry
          );
          if (data.billingCountry != "") {
            country_list = [data.billingCountry, ...country_list];
          }
          if (data.country != "") {
            country_list = [data.country, ...country_list];
          }
          setCountryList(country_list);
        }
      }
      setTimeout(() => {
        setInitial(true);
      }, 1000);
    } else {
      setInitial(false);
    }
  }, [props.page]);

  useEffect(() => {
    if (initial) {
      if (billingSameAsDelivery) {
        setBillingAddressTitle(addressTitle);
        setBillingAddress(address);
        setBillingPincode(pincode);
        setBillingCity(city);
        setBillingCountry(country);
      } else {
        setBillingAddressTitle("");
        setBillingAddress("");
        setBillingPincode("");
        setBillingCity("");
        setBillingCountry("");
      }
    }
  }, [billingSameAsDelivery]);

  useEffect(() => {
    if (
      billingSameAsDelivery &&
      (initial ||
        props.page == "add_address" ||
        props.page == "add_new_address")
    ) {
      setBillingAddressTitle(addressTitle);
    }
  }, [addressTitle]);

  useEffect(() => {
    if (
      billingSameAsDelivery &&
      (initial ||
        props.page == "add_address" ||
        props.page == "add_new_address")
    ) {
      setBillingAddress(address);
    }
  }, [address]);

  useEffect(() => {
    if (
      billingSameAsDelivery &&
      (initial ||
        props.page == "add_address" ||
        props.page == "add_new_address")
    ) {
      setBillingPincode(pincode);
    }
  }, [pincode]);

  useEffect(() => {
    if (
      billingSameAsDelivery &&
      (initial ||
        props.page == "add_address" ||
        props.page == "add_new_address")
    ) {
      setBillingCity(city);
    }
  }, [city]);

  useEffect(() => {
    if (
      billingSameAsDelivery &&
      (initial ||
        props.page == "add_address" ||
        props.page == "add_new_address")
    ) {
      setBillingCountry(country);
    }
  }, [country]);

  const onSubmitAddress = (e) => {
    e.preventDefault();

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(email) == false) {
      setMsgAlert({ open: true, message: "Invalid Email", msgType: "error" });
      return;
    }

    if (phCode == "") {
      setMsgAlert({
        open: true,
        message: "Invalid Phone Code",
        msgType: "error",
      });
      return;
    }

    if (/[^0-9]/g.test(phNo)) {
      setMsgAlert({
        open: true,
        message: "Invalid Phone Number",
        msgType: "error",
      });
      return;
    }

    let data = {
      id:
        props.page == "edit_address" || props.page == "edit_billing_address"
          ? props.editAddress.id
          : new Date(),
      name: recipientName,
      email: email,
      phoneCode: phCode,
      phone: phNo,
      addressTitle: addressTitle,
      address: address,
      pincode: pincode,
      city: city,
      country: country,
      billingAddressTitle: billingAddressTitle,
      billingAddress: billingAddress,
      billingPincode: billingPincode,
      billingCity: billingCity,
      billingCountry: billingCountry,
      billingType: billingType,
      billingSameAsDelivery: billingSameAsDelivery,
    };
    setInitial(false);
    props.onSubmitAddress(data);
  };

  const onSelectBillingType = (val) => {
    setBillingType(val);
  };

  return (
    <Grid component="form" onSubmit={onSubmitAddress}>
      <Box
        sx={{
          ...styles.dataView,
          pt: restTemplate == "temp1" ? 0 : 1,
        }}
      >
        {/*<Typography sx={styles.descText}>*{i18next.t('Required fields.')}</Typography>*/}

        <Box sx={{ ...styles.subView, ...subViewStyle[restTemplate] }}>
          <Box sx={styles.titleView} mb={1} mt={1}>
            {/*<Box sx={styles.titleSlNoView}>*/}
            {/*  <Typography sx={styles.titleSlNoText}>1</Typography>*/}
            {/*</Box>*/}
            <Typography sx={styles.titleText}>
              {i18next.t("Recipients Information")}
            </Typography>
          </Box>
          <ARWebCartTextInput
            page={"add_address"}
            locale={props.locale}
            value={recipientName}
            setValue={setRecipientName}
            required={true}
            label={i18next.t("Name and Surname*")}
            labelStyle={{ color : addAddressCardInputLabelFontColor ?? "#000000" }}
            inputViewStyle={InputViewStyle}
            inputStyle={{ color: addAddressCardInputFontColor ?? "#000"}}
          />

          <Typography mt={2} sx={styles.labelText}>
            {i18next.t("Phone Number")}
          </Typography>
          <Box
            sx={{ ...styles.phInputView, ...InputViewStyle }}
            id={"arWebAddAddressPhInput"}
          >
            <PhoneInput
              country={"de"}
              value={phCode}
              onChange={setPhCode}
              countryCodeEditable={false}
              disableCountryCode={false}
              buttonStyle={{
                backgroundColor: addAddressCardInputBgColor ?? "#FCFCFD",
                border: "0px",
              }}
              inputStyle={{
                width: 85,
                height: 54,
                color: addAddressCardInputFontColor ?? "#000",
                fontSize: 15,
                fontFamily: "GilroyMedium",
                background: addAddressCardInputBgColor ?? "#FCFCFD",
                border: 0,
                paddingLeft: props.locale == "ar" ? "13px" : "42px",
                textAlign: props.locale == "ar" ? "left" : "unset",
                direction: "ltr",
              }}
              containerStyle={{
                height: 54,
                width: 85,
                marginLeft: props.locale == "ar" ? "unset" : 3,
                marginRight: props.locale == "ar" ? 13 : "unset",
              }}
              dropdownStyle={{
                color: "#000",
                fontSize: 15,
                fontFamily: "GilroyMedium",
              }}
            />
            <Box sx={{ width: "100%" }}>
              <ARWebCartTextInput
                page={"add_address"}
                locale={props.locale}
                value={phNo}
                setValue={(value) => setPhNo(value.replace(/[^0-9]/g, ""))}
                type="number"
                required={true}
                inputViewStyle={{ backgroundColor: addAddressCardInputBgColor ?? "#FCFCFD" }}
                labelStyle={{ color : addAddressCardInputLabelFontColor ?? "#000000" }}
                inputStyle={{ color: addAddressCardInputFontColor ?? "#000"}}
              />
            </Box>
          </Box>
          {/*<Typography mb={1} sx={styles.descText}>{i18next.t('For shipping related questions only.')}</Typography>*/}
          <Box mt={2}>
            <ARWebCartTextInput
              page={"add_address"}
              locale={props.locale}
              value={email}
              setValue={setEmail}
              type="email"
              required={true}
              label={i18next.t("E-mail Address")}
              inputViewStyle={InputViewStyle}
              labelStyle={{ color : addAddressCardInputLabelFontColor ?? "#000000" }}
              inputStyle={{ color: addAddressCardInputFontColor ?? "#000"}}
            />
          </Box>
          {/*<Typography sx={styles.descText}>*/}
          {/*  {i18next.t('This address will be used to send you order and bill details.')}*/}
          {/*</Typography>*/}
        </Box>

        <Box sx={{ ...styles.subView, ...subViewStyle[restTemplate] }}>
          <Box sx={styles.titleView} mb={1}>
            {/*<Box sx={styles.titleSlNoView}>*/}
            {/*  <Typography sx={styles.titleSlNoText}>2</Typography>*/}
            {/*</Box>*/}
            <Typography sx={styles.titleText}>
              {i18next.t("Delivery Address")}
            </Typography>
          </Box>
          <ARWebAddressForm
            restTemplate={restTemplate}
            type="shipping"
            locale={props.locale}
            pincodeList={pincodeList}
            cityList={cityList}
            countryList={countryList}
            addressTitle={addressTitle}
            address={address}
            pincode={pincode}
            city={city}
            country={country}
            setAddressTitle={setAddressTitle}
            setAddress={setAddress}
            setPincode={setPincode}
            setCity={setCity}
            setCountry={setCountry}
            setPincodeList={setPincodeList}
            setCountryList={setCountryList}
            setCityList={setCityList}
            themeColors={themeColors}
          />
        </Box>

        <Box sx={{ ...styles.subView, ...subViewStyle[restTemplate] }}>
          <Box sx={styles.titleView} mb={2}>
            {/*<Box sx={styles.titleSlNoView}>*/}
            {/*  <Typography sx={styles.titleSlNoText}>3</Typography>*/}
            {/*</Box>*/}
            <Typography sx={styles.titleText}>
              {i18next.t("Billing Information")}
            </Typography>
          </Box>

          <Typography sx={styles.billingSubTitleText}>
            {i18next.t("Billing Address")}*&lrm;
          </Typography>
          <Box
            mt={0.5}
            sx={styles.sameAddressView}
            onClick={() => setBillingSameAsDelivery(!billingSameAsDelivery)}
          >
            <Checkbox
              checked={billingSameAsDelivery}
              onChange={() => setBillingSameAsDelivery(!billingSameAsDelivery)}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 27 },
                paddingLeft: props.locale == "ar" ? 1 : "unset",
                paddingRight: props.locale == "ar" ? "unset" : 1,
                color: addAddressCheckboxBtnIconColor ?? "#000",
                "&.Mui-checked": {
                  color: addAddressCheckboxBtnIconColor ?? (restTemplate == "temp1" ? "#E8C900" : THEMECOLOR),
                },
              }}
            />
            <Typography sx={styles.sameAddressText}>
              {i18next.t("Same as delivery address.")}&lrm;
            </Typography>
          </Box>

          {billingSameAsDelivery ? null : (
            <ARWebAddressForm
              restTemplate={restTemplate}
              type="billing"
              locale={props.locale}
              pincodeList={pincodeList}
              cityList={cityList}
              countryList={countryList}
              addressTitle={billingAddressTitle}
              address={billingAddress}
              pincode={billingPincode}
              city={billingCity}
              country={billingCountry}
              setAddressTitle={setBillingAddressTitle}
              setAddress={setBillingAddress}
              setPincode={setBillingPincode}
              setCity={setBillingCity}
              setCountry={setBillingCountry}
              setPincodeList={setPincodeList}
              setCountryList={setCountryList}
              setCityList={setCityList}
              themeColors={themeColors}
            />
          )}

          <Typography mt={2} sx={styles.billingSubTitleText}>
            {i18next.t("Billing Type")}*&lrm;
          </Typography>
          <Box mt={1.5} sx={styles.billingTypeMainView}>
            {billing_type.map((x, i) => {
              return (
                <Box
                  key={i}
                  sx={styles.billingTypeView}
                  onClick={() => onSelectBillingType(x.value)}
                >
                  <Box sx={styles.billingTypeCheckBoxView}>
                    {billingType == x.value ? (
                      restTemplate == "temp1" ? (
                        <Box sx={styles.selectedBillingType} />
                      ) : (
                        <RadioOnIcon width={25} height={25} />
                      )
                    ) : (
                      <Box
                        sx={{
                          ...styles.billingTypeCheckBox,
                          ...checkBoxStyle[restTemplate],
                        }}
                      />
                    )}
                  </Box>
                  <Typography
                    ml={props.locale == "ar" ? "unset" : 0.6}
                    mr={props.locale == "ar" ? 0.6 : "unset"}
                    sx={styles.billingTypeText}
                  >
                    {x.label}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>

        <Box
          pb={restTemplate == "temp1" ? 2 : 0}
          px={restTemplate == "temp1" ? 2 : 0}
          sx={styles.btnView}
        >
          <Button
            type="button"
            sx={{ ...styles.cancelBtn, ...cancelBtnViewStyle[restTemplate],
              backgroundColor : addAddressCancelBtnBgColor ?? THEME_COLOR2,
              color : addAddressCancelBtnFontColor ?? "#FFF",
              "&:hover": {
                backgroundColor: `${addAddressCancelBtnBgColor ?? THEME_COLOR2}!important`,
              },
            }}
            onClick={props.onCancelAddress}
          >
            {i18next.t("Cancel")}
          </Button>
          <Button
            type="submit"
            sx={{ ...styles.submitBtn, ...btnViewStyle[restTemplate],
              backgroundColor : addAddressSaveBtnBgColor ?? "#cb6464",
              color : addAddressSaveBtnFontColor ?? "#FFF",
              "&:hover": {
                backgroundColor: `${addAddressSaveBtnBgColor ?? "#cb6464"}!important`,
              },
            }}
          >
            {i18next.t("Save")}
          </Button>
        </Box>
      </Box>
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Grid>
  );
};

export default ARWebAddAddress;

const styles1 = (theme) => ({
  dataView: {},
  subView: {
    backgroundColor: theme?.addAddressCardBgColor ?? "#FFF",
    p: 2,
    pb: 3,
    mb: 2,
  },
  titleView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleSlNoView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FAF4CB",
    width: "32px",
    height: "32px",
    borderRadius: "16px",
  },
  titleSlNoText: {
    color: "#C1A700",
    fontSize: 17,
    fontFamily: "GilroySemiBold",
  },
  titleText: {
    color: theme?.addAddressCardTitleFontColor ?? "#121212",
    fontSize: 18,
    fontFamily: "GilroyBold",
  },
  labelText: {
    color: theme?.addAddressCardInputLabelFontColor ?? "#000000",
    fontSize: 15,
    fontFamily: "GilroyMedium",
  },
  descText: {
    color: "#868E96",
    fontSize: 11,
    fontFamily: "GilroyMedium",
  },
  phInputView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  billingSubTitleText: {
    color: theme?.addAddressCardInputLabelFontColor ?? "#212529",
    fontSize: 14,
    fontFamily: "GilroySemiBold",
  },
  sameAddressView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sameAddressText: {
    color: theme?.addAddressCardInputFontColor ?? "#212529",
    fontSize: 13,
    fontFamily: "GilroyMedium",
    cursor: "pointer",
  },
  billingTypeMainView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  billingTypeView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  billingTypeText: {
    color: theme?.addAddressCardInputFontColor ?? "#212529",
    fontSize: 13,
    fontFamily: "GilroyMedium",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  billingTypeCheckBoxView: {
    width: 30,
    path: {
      fill : theme?.addAddressRadioBtnIconColor ?? "#000",
    }
  },
  selectedBillingType: {
    border: "7px solid #E8C900",
    width: "12px",
    height: "12px",
    borderRadius: "20px",
  },
  billingTypeCheckBox: {
    border: `2px solid ${theme?.addAddressRadioBtnIconColor ?? "#BFBFBF"}`,
    width: "20px",
    height: "20px",
    borderRadius: "15px",
  },
  btnView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cancelBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000000",
    cursor: "pointer",
    textTransform: "capitalize",
    color: "#FFF",
    fontFamily: "GilroyBold",
    fontSize: 18,
    borderRadius: "40px",
    height: 60,
    width: "48%",
    "&:hover": {
      backgroundColor: "#000000 !important",
    },
  },
  submitBtn: {
    backgroundColor: THEME_COLOR,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    color: "#000",
    fontFamily: "GilroyBold",
    fontSize: 18,
    borderRadius: "40px",
    width: "48%",
    height: 60,
    "&:hover": {
      backgroundColor: THEME_COLOR + " !important",
    },
  },
});
