import React from 'react';
import { Box, Typography } from '@mui/material';
import CartItemsWithModifiers from '../../past-orders/components/CartItemsWithModifiers';

const YourOrderWidget = ({ orderData, cartItems, bgColor = null, fontColor = null }) => {
  const orderNumber = orderData?._id.toString().substr(-5);

  return (
    <Box
      sx={{
        mx: 1,
        mt: 3,
        p: 2,
        backgroundColor: bgColor ?? "#FFF",
        boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '15px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: '22px',
            fontFamily: 'Inter',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            color: fontColor ?? '#000000',
          }}
        >
          Your Order
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: 600,
            color: fontColor ?? '#000000',
          }}
        >
          Order no: {orderNumber}
        </Typography>
      </Box>

      <CartItemsWithModifiers cartList={cartItems} fontColor={fontColor} />
    </Box>
  );
};

export default YourOrderWidget;
