import React, { useEffect, useState } from "react";
import { Box, Typography, SwipeableDrawer } from "@mui/material";
import { useSelector } from "react-redux";

import MenuListAddSubMenuBtn from "./MenuListAddSubMenuBtn";
import {
  getModifierTotalPrice,
  onModifierSelect,
  onSetDefaultModifierData,
} from "../constants/commonFunc";
import AddItemToCartInterface from "./AddItemToCartInterface";
import { formatCurrency } from "../utils/utils";
import { MENU_VIEW, trackEvent } from "../constants/analytics";

const AddMenuToCartModal = (props) => {
  const [cartCount, setCartCount] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [addedOptions, setAddedOptions] = useState([]);
  const [addedModifiers, setAddedModifiers] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [notes, setNotes] = useState({});

  let { data } = props;

  const { restData } = useSelector((state) => state.apiData) || {};

  const {
    menuPopupAddToCartBtnBgColor = null,
    menuPopupAddToCartBtnFontColor = null,
    menuPopupBgColor = null,
    menuPopupQtyBtnBgColor = null,
    menuPopupQtyBtnFontColor = null,
  } = restData?.themeColors || {};

  useEffect(() => {
    setModalVisible(props.visible);
    setCartCount(1);
  }, [props.visible]);

  useEffect(() => {
    const modifier = onSetDefaultModifierData(
      props.data?.modifiers,
      props?.menuList,
      "modal"
    );
    setAddedModifiers(modifier);

    if (props.visible) {
      const {_idRestaurant = "", _id = ""} = props?.data;
      if (_idRestaurant && _id) {
        trackEvent(MENU_VIEW, {
          restaurant_id: _idRestaurant,
          item_id: _id,
        });
      }
    }
  }, [props.data]);

  useEffect(() => {
    const menuItemTotalPrice =
      ((data?.price?.value ?? 0) +
        getModifierTotalPrice(
          data?.modifiers?.filter((x) => !x.addUpPrices)?.map((x) => x?._id) ??
            [],
          addedModifiers
        )) *
      cartCount;
    setTotalPrice(menuItemTotalPrice);
  }, [addedModifiers, cartCount]);

  const addSubMenu = (type) => {
    let count = cartCount;
    if (type === "-" && cartCount > 1) {
      count = cartCount - 1;
      addedOptions.pop();
    }
    if (type === "+") {
      count = cartCount + 1;
    }
    setCartCount(count);
  };

  const onCloseModal = () => {
    props.onClose();
  };

  const onModifierClick = (items, menu_data) => {
    const data = [...addedModifiers];
    const value = onModifierSelect(data, items, menu_data);
    setAddedModifiers(value);
  };

  const onNotesChange = (value) => {
    setNotes((prevNotes) => ({
      ...prevNotes,
      [data._id]: value,
    }))
  }

  const onAddMenuItemToCart = () => {
    props.onAddMenuItemToCart(
      props.data,
      notes[data._id] || '',
      addedOptions,
      addedModifiers,
      cartCount
    );
  };

  return (
    <>
      <SwipeableDrawer
        className={"swipeable-drawer-modal"}
        sx={{
          "& .MuiDrawer-root": {
            zIndex: 100,
          },
          "& .MuiPaper-root": {
            overflow: "hidden",
            height: "80vh",
          },
        }}
        anchor="bottom"
        open={modalVisible}
        onClose={onCloseModal}
        onOpen={() => {}}
        disableSwipeToOpen
        transitionDuration={{
          enter: 300,
          exit: 350,
        }}
      >
        <Box
          className={"device-width-pct"}
          sx={{
            flex: 1,
            overflow: "hidden",
            borderTopLeftRadius: "18px",
            borderTopRightRadius: "18px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: menuPopupBgColor ?? "#EBEBEB",
            boxShadow:
              "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
          }}
        >
          <AddItemToCartInterface
            showAddToCart={props.addToCartExist}
            onAddMenuItemToCart={onAddMenuItemToCart}
            onModifierClick={onModifierClick}
            addSubMenu={addSubMenu}
            itemData={data}
            menuList={props.menuList}
            addedModifiers={addedModifiers}
            onClose={onCloseModal}
            onNotesChange={onNotesChange}
            notes={notes[data?._id]}
          />
        </Box>
      </SwipeableDrawer>

      {props?.addToCartExist && modalVisible ? (
        <Box
          sx={{
            position: "fixed",
            left: 0,
            right: 0,
            bottom: "20px",
            zIndex: 50000,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            className={"swipeable-drawer-modal-device-width"}
            sx={{
              height: 58,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MenuListAddSubMenuBtn
                theme={"theme2"}
                bgColor={menuPopupQtyBtnBgColor}
                borderColor={menuPopupQtyBtnFontColor}
                fontColor={menuPopupQtyBtnFontColor}
                count={cartCount ? cartCount : 1}
                inputWidth={"100%"}
                inputHeight={58}
                addSubWidth={50}
                onAddSubMenu={(type) => addSubMenu(type)}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: menuPopupAddToCartBtnBgColor ?? "#000000",
                borderRadius: "10px",
                height: "100%",
                width: "49%",
                color: menuPopupAddToCartBtnFontColor ?? "#ffffff",
              }}
              onClick={onAddMenuItemToCart}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: "bold", textAlign: "center" }}
              >
                Add for {formatCurrency(totalPrice)}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default AddMenuToCartModal;
