import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ReactComponent as LeftArrowIcon } from "../assets/images/left_white_arrow.svg";
import { ReactComponent as CartIcon } from "../assets/images/cart.svg";

import { THEME_COLOR2 } from "../containers/MenuList2";

let bgColor = {
  temp1: "#000",
  temp2: THEME_COLOR2,
};

const ARWebCartHeader = (props) => {
  let { title, cartItems, restTemplate, themeColors = {}, page } = props;
  let cartCount = 0;

  cartItems.map((x) => {
    cartCount = cartCount + x.count;
  });

  const {
    addAddressHeaderBgColor = null,
    addAddressHeaderFontColor = null,
    addAddressBackBtnBgColor = null,
    addAddressBackBtnIconColor = null,
    addressListHeaderBgColor = null,
    addressListHeaderFontColor = null,
    addressListBackBtnBgColor = null,
    addressListBackBtnIconColor = null,
  } = themeColors || {};

  const headerBgColor = {
    cart: "#000",
    add_address : addAddressHeaderBgColor ?? THEME_COLOR2,
    add_new_address : addAddressHeaderBgColor ?? THEME_COLOR2,
    edit_address : addAddressHeaderBgColor ?? THEME_COLOR2,
    edit_billing_address : addAddressHeaderBgColor ?? THEME_COLOR2,
    list_address : addressListHeaderBgColor ?? THEME_COLOR2,
    order_confirm : THEME_COLOR2,
  }

  const headerFontColor = {
    cart: "#FFF",
    add_address : addAddressHeaderFontColor ?? "#FFF",
    add_new_address : addAddressHeaderFontColor ?? "#FFF",
    edit_address : addAddressHeaderFontColor ?? "#FFF",
    edit_billing_address : addAddressHeaderFontColor ?? "#FFF",
    list_address : addressListHeaderFontColor ?? "#FFF",
    order_confirm : "#FFF",
  }

  const backBtnBgColor = {
    cart: "#2f4974",
    add_address : addAddressBackBtnBgColor ?? "#2f4974",
    add_new_address : addAddressBackBtnBgColor ?? "#2f4974",
    edit_address : addAddressBackBtnBgColor ?? "#2f4974",
    edit_billing_address : addAddressBackBtnBgColor ?? "#2f4974",
    list_address : addressListBackBtnBgColor ?? "#2f4974",
    order_confirm : "#2f4974",
  }

  const backBtnIconColor = {
    cart: "#FFF",
    add_address : addAddressBackBtnIconColor ?? "#FFF",
    add_new_address : addAddressBackBtnIconColor ?? "#FFF",
    edit_address : addAddressBackBtnIconColor ?? "#FFF",
    edit_billing_address : addAddressBackBtnIconColor ?? "#FFF",
    list_address : addressListBackBtnIconColor ?? "#FFF",
    order_confirm : "#FFF",
  }

  return (
    <Box
      sx={{
        ...styles.headerMainView,
        backgroundColor: headerBgColor?.[page] ?? bgColor[restTemplate],
      }}
      dir={props.page == "cart" ? "" : "ltr"}
    >
      <Box sx={styles.headerLeftView}>
        <Box
          sx={[
            styles.headerLeftIcon,
            {
              transform:
                props.page == "cart"
                  ? props.locale == "ar"
                    ? "scaleX(-1)"
                    : "unset"
                  : "unset",
            },
            {
              paddingLeft:
                props.page == "cart"
                  ? "unset"
                  : props.locale == "ar"
                  ? "30px"
                  : "unset",
            },
          ]}
          dir={"ltr"}
          onClick={props.backClick}
        >
          {restTemplate == "temp1" || props.page == "cart" ? (
            <LeftArrowIcon width={25} height={25} />
          ) : (
            <Box sx={{
              ...styles.leftIconView,
              backgroundColor: backBtnBgColor?.[page] ?? "#2f4974",
              path: { stroke : backBtnIconColor?.[page] ?? "#FFF"},
            }}>
              <LeftArrowIcon width={25} height={25} />
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={styles.headerTitleView}>
        <Typography sx={{...styles.headerTitleText, color: headerFontColor?.[page] ?? "#FFF" }}>
          {title}
        </Typography>
      </Box>
      <Box
        sx={styles.headerRightView}
        pr={props.locale == "ar" ? "unset" : 4}
        pl={props.locale == "ar" ? 4 : "unset"}
      >
        {restTemplate == "temp1" ? (
          props.page == "cart" ? null : (
            <Box sx={styles.cartView}>
              <CartIcon width={25} height={25} />
              <Box sx={styles.cartCountView}>
                <Typography sx={styles.cartCountText}>
                  {cartCount > 9 ? "9+" : cartCount}
                </Typography>
              </Box>
            </Box>
          )
        ) : null}
      </Box>
    </Box>
  );
};

export default ARWebCartHeader;

const styles = {
  headerMainView: {
    position: "sticky",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    top: 0,
    px: 2,
    py: 2,
    zIndex: 10,
  },
  headerLeftView: {
    width: "15%",
  },
  headerLeftIcon: {
    cursor: "pointer",
  },
  leftIconView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: "50%",
  },
  headerTitleView: {
    width: "70%",
  },
  headerTitleText: {
    fontSize: 18,
    fontFamily: "GilroyMedium",
    textAlign: "center",
  },
  headerRightView: {
    display: "flex",
    justifyContent: "flex-end",
    width: "15%",
  },
  cartView: {
    position: "relative",
  },
  cartCountView: {
    backgroundColor: "#FF2727",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    borderRadius: "10px",
    top: -5,
    right: -5,
  },
  cartCountText: {
    color: "#FFF",
    fontSize: 9,
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
};
