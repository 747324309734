import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { ReactComponent as SearchIcon } from "../assets/images/search.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close.svg";

const ICON_SIZE = 18;

const MenuSearchInput = (props) => {
  const { iconColor = null, fontColor = null } = props;

  let inputBg = props?.inputBg ? props.inputBg : "#FFF";

  return (
    <TextField
      fullWidth
      focused
      value={props.value}
      variant="filled"
      placeholder={props.placeholder}
      autoFocus={props.value == ""}
      hiddenLabel={true}
      onChange={(e) => props.setValue(e.target.value)}
      sx={{
        "& .MuiInputBase-root": {
          border: props?.border ? props.border : "0px",
          "&:hover": {
            backgroundColor: inputBg,
          },
          "&.Mui-focused": {
            backgroundColor: inputBg,
          },
          "&.Mui-disabled": {
            backgroundColor: inputBg,
          },
        },
      }}
      InputLabelProps={{
        sx: {
          fontFamily: "GilroySemiBold",
        },
      }}
      InputProps={{
        disableUnderline: true,
        autoComplete: "none",
        sx: {
          backgroundColor: inputBg,
          color: fontColor ?? "#000",
          fontSize: 12,
          fontFamily: "GilroySemiBold",
          height: props?.height ? props.height : 40,
          borderRadius: 0,
          width: "100%",
        },
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              cursor: "pointer",
              path: { fill: iconColor ?? "#000" },
            }}
          >
            <SearchIcon height={ICON_SIZE} width={ICON_SIZE} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            onClick={props.onClose}
            position="end"
            sx={{
              marginLeft: 1,
              cursor: "pointer",
              path: { fill: iconColor ?? "#000" },
            }}
          >
            <CloseIcon height={ICON_SIZE} width={ICON_SIZE} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MenuSearchInput;
