import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setDefaultEventParameters,
} from "firebase/analytics";

import { FIREBASE_CONFIG } from "./index";

// Initialize Firebase
let app = null;
let analytics = null;

export function initAnalytics() {
  // Initialize Analytics and get a reference to the service
  app = initializeApp(FIREBASE_CONFIG);
  analytics = getAnalytics(app);
}

export function trackEvent(event, obj = null) {
  if (obj) {
    console.log("trackEvent", event, obj);
    logEvent(analytics, event, obj);
  } else {
    console.log("trackEvent", event);
    logEvent(analytics, event);
  }
}

export const MENU_VIEW = "menu_view";
export const ADD_TO_CART = "add_to_cart";
