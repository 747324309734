import React from 'react';
import { Box, IconButton, Typography, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import CheckoutHeader from '../eazy-peasy-checkout/components/shared/CheckoutHeader';
import { formatCurrency } from '../../utils/utils';
import { useOrders } from './context/OrdersContext';
import OrderStatus from './components/OrderStatus';
import { formatDate } from '../../utils/utils';
import {useSelector} from "react-redux";

const PastOrdersPage = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = React.useState('');

  const themeColors = useSelector((state) => state.restaurantData?.restaurantDetail?.themeColors);

  const {
    orderHistoryBgColor = null,
    orderHistoryCardBgColor = null,
    orderHistoryCardFontColor = null,
    orderHistoryCardTableNoBgColor = null,
    orderHistoryCardTableNoFontColor = null,
    orderHistoryHeaderBgColor = null,
    orderHistoryHeaderFontColor = null,
    orderHistorySearchInputBgColor = null,
    orderHistorySearchInputFontColor = null,
    orderHistorySearchInputLabelFontColor = null,
  } = themeColors || {};

  const pastOrders = JSON.parse(localStorage.getItem('pastOrders'));
  const sortedPastOrders = pastOrders.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const filteredPastOrders = sortedPastOrders.filter((order) =>
    JSON.stringify(order).toLowerCase().includes(searchQuery.toLowerCase())
  );

  const { restaurantIdCtx, tableNumberCtx } = useOrders();

  const handleBackButtonClick = () => {
    navigate(`/cart/${restaurantIdCtx}/table/${tableNumberCtx}`);
  };

  const handleNavigateToPastOrderDetails = (pastOrderId) => {
    navigate(`/restaurant/past-orders/order/${pastOrderId}`, {
      state: {
        order: sortedPastOrders.find((item) => item._id === pastOrderId),
      },
    });
  };

  return (
    <Box className={'device-width-view'}>
      <Box className={'device-width-pct'}>
        <CheckoutHeader
          headerTitle='Your Orders'
          onBackButtonClick={handleBackButtonClick}
          bgColor={orderHistoryHeaderBgColor ?? "#FFF"}
          fontColor={orderHistoryHeaderFontColor ?? "#000"}
        />
      </Box>

      <Box
        className={'device-width-pct'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          height: '100vh',
          boxSizing: 'border-box',
          maxHeight: '100vh',
          overflowY: 'auto',
          backgroundColor: orderHistoryBgColor ?? '#FAFBFF',
        }}
      >
        <Box sx={{ p: 2 }}>
          <TextField
            label='Search Orders'
            variant='outlined'
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              mb: 2,
              backgroundColor: orderHistorySearchInputBgColor ?? 'transparent',
              "& .MuiInputBase-input": {
                color: orderHistorySearchInputFontColor ?? '#000000de',
              },
              "& .MuiInputLabel-root": {
                color: orderHistorySearchInputLabelFontColor ?? '#00000099',
              }
            }}
          />

          {filteredPastOrders?.map((order) => {
            if (order?.cart.length === 0) {
              return null;
            }
            return (
              <Box
                key={order._id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'stretch',
                  padding: 2,
                  backgroundColor: orderHistoryCardBgColor ?? '#FFFFFF',
                  mb: 2,
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '5px',
                }}
                onClick={() => handleNavigateToPastOrderDetails(order._id)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant='h6'
                    sx={{
                      color: orderHistoryCardFontColor ?? "#000",
                      fontWeight: 500,
                      fontFamily: 'Inter',
                      fontSize: 14,
                      lineHeight: 1.4,
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    Order: #{order?._id?.toUpperCase().substr(-5)}
                    <Box
                      sx={{
                        background: orderHistoryCardTableNoBgColor ?? 'rgba(0, 0, 0, 0.1)',
                        px: 0.5,
                        py: 0.25,
                        borderRadius: '5px',
                      }}
                    >
                      <Typography
                        variant='h6'
                        sx={{
                          color: orderHistoryCardTableNoFontColor ?? "#000",
                          fontFamily: 'Inter',
                          fontWeight: 600,
                          fontSize: 10,
                        }}
                      >
                        Table {order?.tableNumber}
                      </Typography>
                    </Box>
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant='h6'
                      sx={{
                        color: orderHistoryCardFontColor ?? "#000",
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: 1.4,
                      }}
                    >
                      {formatCurrency(order?.totalAmount)}
                    </Typography>
                  </Box>
                </Box>

                <Typography
                  sx={{
                    color: orderHistoryCardFontColor ?? "#000",
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: 1.4,
                    py: 1.5,
                  }}
                >
                  {order?.cart.length}{' '}
                  {order?.cart.length === 1 ? 'item' : 'items'}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: orderHistoryCardFontColor ?? "#000",
                      fontFamily: 'Inter',
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Placed at {formatDate(order?.createdAt)}
                  </Typography>

                  <OrderStatus status={order?.transactionStatus} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default PastOrdersPage;
